import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  // The styles all button have in common
  // Two sizes: sm and md
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
    },
    solid: {
      bg: 'black',
      color: 'white',
      _hover: {
        bg: 'black',
        color: 'white',
      },
    },
    hero: {
      border: '1px solid',
      borderColor: 'white',
      color: 'white',
      rounded: 'none',
      _hover: {
        bg: 'black',
        color: 'white',
      },
    },
    language: {
      
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
})